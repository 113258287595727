export default {
  basicViewItem: [{
    prop: 'active',
    label: '',
    Object: 'value',
    span: 12,
    back: true
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'sites',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'package_type',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'location',
    label: '',
    Object: 'value',
    span: 12
  }, {
    span: 12,
    prop: 'image',
    label: '',
    image: true
  }, {
    prop: 'terms_and_conditions',
    name: 'input',
    type: 'textarea',
    Object: 'value',
    span: 24
  }, {
    prop: 'short_desc',
    label: '',
    Object: 'value',
    span: 12
  }],
  ticket_types: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'attraction',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'ticket_package',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'listing_price',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'original_price',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'third_party_platform',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'parent_ticket_type',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'ranking',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'active',
    label: '',
    Object: 'value',
    width: '80'
  }]
}

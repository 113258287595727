// 组件
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
// 接口
import { getTicketDetail, deleteZooTickets } from '@/services/zoo-tickets.js'
import { getHistoryList } from '@/services/resource'
// 其他
import loading from '@/utils/loading.json'
import history from '@/common/data/jsonData'
import jsonData from './jsonData'
import { mixin } from '@/mixins/viewDiolog'

export default {
  mixins: [mixin],
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      basicViewItem: jsonData.basicViewItem,
      historyTableItem: history.historyTableItem,
      tableDataViewItem: jsonData.tableDataViewItem,
      ticket_types: jsonData.ticket_types,
      dialogData: [],
      batchData: [],
      dialogVisible: false,
      historyTableData: [],
      language: this.$cookies.get('language') || navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0] || 'en'
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    _this.init()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    handleBaseClick () {
      if (this.$route.params.ticketType === 'Bundle Ticket' || this.$route.params.ticketType === '套票') {
        this.$router.push({
          name: 'basicTicketPackageList',
          params: {
            ticket_package_id_eq: this.$route.params.id
          }
        })
      } else if (this.$route.params.ticketType === 'Multi Park' || this.$route.params.ticketType === '多园票') {
        this.$router.push({
          name: 'basicMultiParkTicketsList',
          params: {
            ticket_package_id_eq: this.$route.params.id
          }
        })
      } else {
        this.$router.push({
          name: 'basicTicketsTypeList',
          params: {
            ticket_package_id_eq: this.$route.params.id
          }
        })
      }
    },
    // 初始化
     async init () {
      const _this = this
      _this.setGlobalLoading(true)
	  const res = await getTicketDetail(_this.urlParams.id)
	  if (res.status === 200) {
		  _this.custName = res.data.ticket_package
		  this.getDetail()
		  Object.assign(this.titleObject, { title: this.$t('form.zoo.ticket_package') + ': ', data: this.language === 'en' ? _this.custName.basic_information.name.value : _this.custName.basic_information.name.value })
	  }
	  _this.setGlobalLoading(false)
      //  获取历史操作
      getHistoryList({ item_type: 'TicketPackage', item_id: _this.urlParams.id }).then(res => {
        this.historyTableData = res.data.versions
      })
    },
    forwardToPage (data, name) {
    },
    getDetail () {
      if (this.custName && this.custName.ticket_types && this.custName.ticket_types.length > 0) {
        Object.keys(this.custName.ticket_types[0]).forEach(item => {
          const index = this.ticket_types.findIndex(fditem => fditem.prop === item)
          if (index !== -1) this.ticket_types[index].label = this.custName.ticket_types[0][item].name
        })
      }
      console.log(this.ticket_types)
    },
    // 点击操作按钮
    async handleClick (type, data) {
      console.log('---handleClick--data--', data)
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'zooTicketsEdit',
          params: {
            id: this.$route.params.id
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(this.$route.params.id, deleteZooTickets, 'zooTicketsList')
      }
    }
  }
}

<template>
  <div class="managerDeliveryOrderView">
    <view-frame :titleObject="titleObject" :buttonObj="buttonObj">
      <template #rightBtn="scope">
        <el-button class="margintop10" type="primary" size="mini" @click="handleBaseClick">{{$t('form.zoo.manageTickets')}}</el-button>
        <template v-if="custName && custName.member_actions && custName.member_actions.default_actions">
          <el-button v-show="item.action !== 'view'" v-for="(item, index) in custName.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
        </template>
        <template v-if="custName && custName.member_actions && custName.member_actions.custom_actions">
          <el-button v-for="(item, index) in custName.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </template>
      <template>
        <el-card>
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{ $t('text.basicInfo') }}</span>
              <el-card class="animate__animated animate__fadeInDown" shadow="hover">
                <custome-table @forwardToPage="forwardToPage" tableType="object" :custSpan="6" :data="custName.basic_information" :dataItem="basicViewItem"></custome-table>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{ $t('form.zoo.allTickets') }}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                <span class="view__text"></span>
                <createTable :autoWidth="true" :tableDataItem="ticket_types" :operateVisible="false" :tableData="custName.ticket_types"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{ $t('form.common.changeHistory') }}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                <span class="view__text"></span>
                <createTable :autoWidth="true" :tableDataItem="historyTableItem" :operateVisible="false" :tableData="historyTableData"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </template>
    </view-frame>
    <dialogEffect v-if="dialogVisible" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
</script>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
</style>
